/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-01-21 16:31:01
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-08 15:37:29
 */
export const columns = [
  {
    title: '打款单号',
    dataIndex: 'collectionCode',
    key: 'collectionCode',
    width: 220,
    align:'center',
  },
  {
    title: '大区',
    dataIndex: 'areaName',
    key: 'areaName',
    align:'center',
    scopedSlots: { customRender: 'areaName'}
},
{
    title: '事务所',
    dataIndex: 'firmName',
    key: 'firmName',
    align:'center',
    // width: 200,
    scopedSlots: { customRender: 'firmName'}
},
  {
    title: '经销商',
    dataIndex: 'dealerName',
    key: 'dealerName',
    align:'center',
    // width: 200,
    scopedSlots: { customRender: 'dealerName'}
},
{
    title: '打款时间',
    dataIndex: 'paymentTime',
    key: 'paymentTime',
    width:150,
    align:'center',
    ellipsis:true
},
{
  title: '状态',
  dataIndex: 'auditStatus',
  key: 'auditStatus',
  /*width:150,*/
  align:'center',
  scopedSlots: { customRender: 'auditStatus'}
},
  {
    title: '收款银行',
    dataIndex: 'bankName',
    key: 'bankName',
    /*width:150,*/
    align:'center'
  },
{
    title: '收款方式',
    dataIndex: 'amountType',
    key: 'amountType',
    /*width:150,*/
    align:'center',
    scopedSlots: { customRender: 'amountType'}
},
{
    title: '打款金额',
    dataIndex: 'amount',
    key: 'amount',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: {
      customRender: 'amount'
    }
},
{
    title: '打款类型',
    dataIndex: 'paymentType',
    key: 'paymentType',
    /*width:150,*/
    align:'center',
    scopedSlots: {
        customRender: 'paymentType'
      }
},
  {
    title: '所属商品类目',
    dataIndex: 'categoryName',
    key: 'categoryName',
    /*width:150,*/
    align:'center'
  },
// {
//     title: '业务部门',
//     dataIndex: 'lingganResponsibilityDep',
//     key: 'lingganResponsibilityDep',
//     /*width:150,*/
//     align:'center',
//     ellipsis:true
// },
{
    title: '业务员',
    dataIndex: 'lingganResponsibilityName',
    key: 'lingganResponsibilityName',
    /*width:150,*/
    align:'center',
    scopedSlots: {
      customRender: 'lingganResponsibilityName'
    }
},
  {
    title: '营业担当',
    dataIndex: 'accountExcutiveName',
    key: 'accountExcutiveName',
    /*width:150,*/
    align:'center',
    scopedSlots: {
      customRender: 'accountExcutiveName'
    }
  },
{
  title: '业绩归属经销商',
  dataIndex: 'belongDealerName',
  key: 'belongDealerName',
  /*width:150,*/
  align:'center'
},
{
  title: '用途',
  dataIndex: 'topUpPurpose',
  key: 'topUpPurpose',
  /*width:150,*/
  align:'center',
  scopedSlots: {
    customRender: 'topUpPurpose'
  }
},
{
    title: '创建人',
    dataIndex: 'createUser',
    key: 'createUser',
    /*width:150,*/
    align:'center',
    width: 100,
    ellipsis:true
},
{
  title: '创建时间',
  dataIndex: 'createTime',
  key: 'createTime',
  /*width:150,*/
  align:'center',
  width: 200,
},
]
