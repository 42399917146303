<template>
  <a-spin :spinning="spinning">
    <a-card :border="false">
      <div class="realtor">
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
          <a-row :gutter="8">
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="大区事务所">
                <a-cascader
                  :options="dealerList"
                  v-model="regionalOffice"
                  change-on-select
                  placeholder="请选择大区/事务所"
                  @change="onChange"
                  :field-names="{ label: 'title', value: 'id', children: 'children' }"
                />
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="经销商">
                <a-input v-model="searchData.dealerName" placeholder="请输入经销商名称" allowClear></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="业绩归属">
                <a-input v-model="searchData.belongDealerName" placeholder="业绩归属经销商名称" allowClear></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="打款时间">
                <DateRange :startTime.sync="searchData.startTime" :endTime.sync="searchData.endTime"></DateRange>
                <!-- <DatePicker :startTime.sync="searchData.startTime"></DatePicker> -->
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="打款状态">
                <DictSelect
                  field="authStatus"
                  :value.sync="searchData.auditStatus"
                  style="width: 100%"
                  placeholder="打款状态"
                ></DictSelect>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="收款方式">
                <DictSelect
                  field="SumType"
                  :value.sync="searchData.amountType"
                  style="width: 100%"
                  placeholder="收款方式"
                ></DictSelect>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="业务员">
                <a-input
                  v-model="searchData.lingganResponsibilityName"
                  placeholder="请输入业务员名称"
                  allowClear
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="创建人">
                <a-input v-model="searchData.createUser" placeholder="请输入创建人名称" allowClear></a-input>
              </a-form-model-item>
            </a-col>

            <a-col :xxl="6" :xl="5" :md="8" sm="2">
              <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
                <a-button type="default" @click="reset()" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div>
          <a-button
            type="primary"
            @click="toHandler('check')"
            icon="copy"
            v-if="isOrderPriceSelect && selectedRows.length == 1"
            >查看</a-button
          >
          <a-button type="primary" @click="toHandler('add')" icon="plus" v-if="isOrderPriceAdd">新增</a-button>
          <!--      <a-button type="primary" @click="visible = true" v-if="isAudit && selectedRows.length == 1 && selectedRows[0].auditStatus == 1">审核</a-button>-->
          <a-button
            v-if="selectedRows.length >= 1 && selectedRows[0].auditStatus == 1"
            type="primary"
            @click="batchSubmitAudit"
            >提交审核</a-button
          >
          <a-button
            v-if="selectedRows.length >= 1 && selectedRows[0].auditStatus == 2 && selectedRows[0].taskId != null"
            type="primary"
            @click="toHandlerAll"
            >审核</a-button
          >
          <!--      <a-button type="primary"  @click="downItemExcel">导出Excel</a-button>-->
          <!-- <downLoad method="get" api="/api/customer/customer/financeReceipt/exportFinanceExcel" :params="searchData" name="收款单.xls" v-if="isOrderPriceExport"></downLoad> -->
          <a-button
            v-if="selectedRows.length == 1 && selectedRows[0].auditStatus == 1"
            type="primary"
            @click="toHandler('edit')"
            icon="edit"
            >编辑</a-button
          >
          <a-button
            v-if="isRollBack && selectedRows.length == 1 && selectedRows[0].auditStatus == 2"
            type="default"
            @click="toHandler('rollbackStatus')"
            icon="rollback"
            >撤审</a-button
          >

          <a-button
            v-if="isReturn && selectedRows.length == 1 && selectedRows[0].auditStatus == 3"
            type="default"
            @click="toHandler('returnStatus')"
            icon="rollback"
            >返审</a-button
          >
          <a-button
            v-if="isDelete && selectedRows.length == 1 && selectedRows[0].auditStatus == 1"
            type="default"
            @click="toHandler('del')"
            icon="delete"
            >删除</a-button
          >
          <a-upload name="file" :action="uploadUrl" :headers="headers" :showUploadList="false" @change="uploadChange">
            <a-button v-if="isReceiptImport" icon="upload" type="default">导入</a-button>
          </a-upload>
          <downLoad
            v-if="isOrderPriceExport"
            method="get"
            @downLoadDone="downLoadDone"
            @startDownLoad="startDownLoad"
            api="/api/dealer/dealer/dealerPaymentFrom/excelExportPlayInfo"
            :params="searchData"
            name="打款单.xls"
            >导出</downLoad
          >
          <a-button type="default" icon="download" @click="downLoadTemp">
            <slot>下载导入模版</slot>
          </a-button>
        </div>
        <!-- 数据表格 -->
        <a-table
          :loading="tableLoading"
          :row-selection="rowSelection"
          :rowKey="(record) => record.id"
          :columns="columns"
          :data-source="tableData"
          bordered
          :pagination="page"
          @change="changeTable"
          :customRow="changeTableRow"
          :scroll="{ x: 2500 }"
        >
          <span slot="paymentType" slot-scope="text">
            <span v-if="text == 1">个人</span>
            <span v-if="text == 2">公司</span>
          </span>
          <span slot="amountType" slot-scope="text, row">
            <span v-if="text == 1">承兑（承兑时长：{{ row.acceptanceName ? row.acceptanceName : '未设置' }}）</span>
            <span v-if="text == 2">电汇</span>
            <span v-if="text == 3">销售折让</span>
          </span>
          <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
          <span slot="auditStatus" slot-scope="text">
            <a-tag color="#999999" v-if="text == 1">未提交审核</a-tag>
            <a-tag color="#e6a23c" v-if="text == 2">待审核</a-tag>
            <a-tag color="#67c23a" v-if="text == 3">审核通过</a-tag>
            <a-tag color="#ff7875" v-if="text == 4">审核拒绝</a-tag>
          </span>
          <span slot="lingganResponsibilityName" slot-scope="text, row">
            【{{ row.lingganResponsibilityDep }}】{{ row.lingganResponsibilityName }}
          </span>
          <span slot="accountExcutiveName" slot-scope="text, row">
            【{{ row.accountExcutiveDep }}】{{ row.accountExcutiveName }}
          </span>
        </a-table>
        <!-- 编辑表单 -->
        <FinanceReceipt-edit-modal ref="FinanceReceiptEditModal" @reload="getData"></FinanceReceipt-edit-modal>
        <!-- 查看-->
        <FinanceReceipt-info-modal ref="FinanceReceiptInfoModal" @reload="getData"></FinanceReceipt-info-modal>
        <a-modal
          title="审核"
          width="40%"
          :visible="visible"
          :confirm-loading="confirmLoading"
          :footer="null"
          @cancel="visible = false"
          :maskClosable="false"
        >
          <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
            <a-textarea placeholder="审核备注" v-model="rejectReason"></a-textarea>
          </a-form-model>
          <div class="footer-bts">
            <a-button type="default" @click="visible = false">取消</a-button>
            <a-button type="danger" :loading="loading" @click="onAudit('refuse')">拒绝</a-button>
            <a-button type="primary" :loading="loading" @click="onAudit('audit')">通过</a-button>
          </div>
        </a-modal>

        <a-modal
          title="批量审核"
          width="40%"
          :visible="visibleAll"
          :footer="null"
          @cancel="visibleAll = false"
          :maskClosable="false"
        >
          <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
            <a-textarea placeholder="审核备注" v-model="verifyRemark"></a-textarea>
          </a-form-model>
          <div class="footer-bts">
            <a-button type="default" @click="handleCanceAll">取消</a-button>
            <a-button type="danger" :loading="loadingAll" @click="onAuditAll('refuse')">拒绝</a-button>
            <a-button type="primary" :loading="loadingAll" @click="onAuditAll('audit')">通过</a-button>
          </div>
        </a-modal>
      </div>
    </a-card>
  </a-spin>
</template>

<script>
import { columns } from './components/colums.js'
import FinanceReceiptEditModal from './components/FinanceReceiptEditModal.vue'
import FinanceReceiptInfoModal from './components/FinanceReceiptInfoModal.vue'

import { delFinanceReceipt, listFinanceReceipt, downExcel, rollbackStatus, returnStatus } from './api/FinanceReceiptApi'
import { checkPermission } from '@/utils/permissions'
import { baseURL } from '@/utils/request'
import { auditTaskBatch } from '@/api/activiti'
import { PAYMENT_TEMP } from '@/utils/downloadTempHref'
const qs = require('qs')

export default {
  name: 'financeReceipt',
  components: {
    FinanceReceiptEditModal,
    FinanceReceiptInfoModal,
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedRows: [],
      isOrderPriceSelect: checkPermission('order:price:select'),
      isOrderPriceAdd: checkPermission('order:price:add'),
      isOrderPriceEdit: checkPermission('order:price:edit'),
      isOrderPriceExport: checkPermission('order:price:export'),
      isAudit: checkPermission('order:price:audit'),
      isReceiptImport: checkPermission('order:receipt:import'),
      isDelete: checkPermission('order:receipt:delete'),
      isRollBack: checkPermission('order:receipt:rollback'),
      isReturn: checkPermission('order:receipt:return'),
      confirmLoading: false,
      visible: false,
      visibleAll: false,
      loading: false,
      loadingAll: false,
      rejectReason: '',
      verifyRemark: '',
      dealerList: [],
      acceptanceList: [],
      dhTypeSourceList: [],
      regionalOffice: [],
      dataList: [],
      //上传的数据
      uploadData: [],
      //文件数据
      fileList: [],
      //上传文件地址
      uploadUrl: baseURL + '/api/dealer/dealer/dealerPaymentFrom/excelReader',
      //上传文件请求头
      headers: {
        'Authorization-Admin': this.$store.getters.token,
      },
      downloadTempHref: PAYMENT_TEMP,
      spinning: false,
      distributor_name: this.$route.params.dealerName,
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
    getToken() {
      return this.$sotre.state.SET_TOKEN()
    },
  },
  mounted() {
    if (this.distributor_name) {
      this.searchData.dealerName = this.distributor_name
    }
    this.getData()
  },
  methods: {
    downLoadTemp() {
      window.open(this.downloadTempHref, '_blank')
    },
    startDownLoad() {
      this.spinning = true
    },
    downLoadDone() {
      this.spinning = false
    },
    //导入
    uploadChange(info) {
      this.tableLoading = true
      setTimeout(() => {
        if (info.file.response) {
          let hint = info.file.response
          if (hint.code === 200) {
            this.tableLoading = false
            this.$message.success(hint.message)
            this.getData()
          } else if (hint.code === 500) {
            this.tableLoading = false
            this.$message.error(hint.message)
          }
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList)
          }
        }
      })
    },
    // 批量审核弹框
    toHandlerAll() {
      let everyResult = this.selectedRows.every((item) => {
        return item.auditStatus === 2 && item.taskId != null
      })
      if (everyResult) {
        this.visibleAll = true
        this.dataList = this.selectedRows
      } else {
        this.$notification.error({ message: '请选择相同待审核记录' })
      }
    },

    /**
     * 批量提交审核
     */
    batchSubmitAudit() {
      var _this = this
      const everyResult = this.selectedRows.every((item) => {
        return item.auditStatus === 1
      })
      if (everyResult) {
        this.dataList = this.selectedRows
        _this.$confirm({
          title: '提交审核',
          content: '确认要提交审核吗?',
          okText: '确认',
          cancelText: '取消',
          async onOk() {
            _this.tableLoading = true
            const query = {
              ids: _this.dataList.map((item) => item.id),
            }
            _this.axios.post('/api/dealer/dealer/dealerPaymentFrom/batchSubmitAudit', query).then((res) => {
              if (res.code == 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
                _this.getData()
              }
            })
          },
          onCancel() {},
        })
      } else {
        this.$notification.error({ message: '请选择未提交审核记录' })
      }
    },

    // 审核，现替换成审批流方法
    // onAuditAll(name) {
    //   const _this = this
    //   _this.loadingAll = true
    //   let query = {
    //     authStatus: name == 'audit' ? 3 : 4,
    //     verifyRemark: _this.verifyRemark,
    //   }
    //   query.ids = _this.dataList.map((item) => item.id)
    //   console.log(query)
    //   _this.axios.post('/api/dealer/dealer/dealerPaymentFrom/toExamineList', query).then(res => {
    //       if(res.code == 200) {
    //          setTimeout(() => {
    //           _this.$notification.success({ message: res.message })
    //           _this.getData()
    //           _this.verifyRemark = ''
    //           _this.visibleAll = false;
    //           _this.loadingAll = false;
    //      }, 3000);
    //       }else {
    //           _this.$notification.error({ message: res.message })
    //           _this.visibleAll = false;
    //           _this.getData()
    //       }
    //   })
    //
    // },

    onAuditAll(name) {
      const _this = this
      _this.loadingAll = true
      let result = 2
      if (name == 'refuse') {
        result = 3
      }
      auditTaskBatch({
        taskIds: _this.dataList.map((item) => item.taskId),
        result: result,
        comment: _this.verifyRemark,
      }).then((res) => {
        if (res.code == 200) {
          setTimeout(() => {
            _this.$message.success('审核成功')
            _this.getData()
            _this.verifyRemark = ''
            _this.visibleAll = false
            _this.loadingAll = false
          }, 3000)
        } else {
          _this.$message.error(res.message)
          _this.getData()
          _this.verifyRemark = ''
          _this.visibleAll = false
          _this.loadingAll = false
        }
      })
    },

    handleCanceAll() {
      this.visibleAll = false
      this.verifyRemark = ''
      this.loadingAll = false
    },
    downItemExcel() {
      const _this = this
      const params = _this.tableData
      downExcel(params)
        .then((res) => {
          console.log('进不来')
        })
        .catch((error) => {
          const blob = new Blob([error], {
            type: 'application/vnd.ms-excel',
          })
          const objectUrl = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = objectUrl
          a.download = '收款单'
          //a.click();
          //下面这个写法兼容火狐
          a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
          window.URL.revokeObjectURL(blob)
        })
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      listFinanceReceipt({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))

      this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
        // 获取大区事务所经销商数据
        this.dealerList = res.body
      })
      this.axios.get('/api/base/system/dictionary/selectByCode/' + '兑换时长').then((res) => {
        // 获取字典电汇类型来源
        this.acceptanceList = res.body
      })

      this.axios.get('/api/base/system/dictionary/selectByCode/' + '电汇类型来源').then((res) => {
        // 获取字典兑换时长
        this.dhTypeSourceList = res.body
      })
    },

    onChange(val) {
      this.searchData.areaId = val[0]
      this.searchData.firmId = val[1]
    },

    // 审核
    onAudit(name) {
      const _this = this
      _this.loading = true
      let query = {
        id: _this.selectedRows[0].id,
        auditStatus: name == 'audit' ? 3 : 4,
        reviewNotes: _this.rejectReason,
        dealerId: _this.selectedRows[0].dealerId,
      }
      _this.axios.post('/api/dealer/dealer/dealerPaymentFrom/toExamine', query).then((res) => {
        if (res.code == 200) {
          _this.$notification.success({ message: res.message })
          _this.getData()
          _this.rejectReason = ''
          _this.visible = false
        } else {
          _this.$notification.error({ message: res.message })
          _this.getData()
        }
      })
      setTimeout(() => {
        _this.visible = false
        _this.loading = false
      }, 3000)
    },

    // 查看
    onCheck(item) {
      const _this = this
      _this.$refs.FinanceReceiptInfoModal.setRowData(item, 'check')
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.regionalOffice = []
      // this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.loadingAll = false
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },

    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.FinanceReceiptEditModal.setRowData({}, 'add')
        return
      }

      if (_this.selectedRows.length === 0 || _this.selectedRows.length > 1) {
        return _this.$message.warning('请选择一条记录')
      }
      switch (name) {
        case 'edit':
          _this.$refs.FinanceReceiptEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'export':
          window.location.href = `${process.env.VUE_APP_API_BASE_URL}/api/customer/customer/financeReceipt/excel=${this.selectedRowKeys[0]}`
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delFinanceReceipt(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'check':
          _this.$refs.FinanceReceiptInfoModal.setRowData(_this.selectedRows[0], 'check')
          break
        case 'rollbackStatus':
          _this.$confirm({
            title: '撤审警告',
            content: '确认要撤回此条记录的审核吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await rollbackStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'returnStatus':
          _this.$confirm({
            title: '返审警告',
            content: '确认要将此条记录返审至草稿状态吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await returnStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
      }
    },
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
